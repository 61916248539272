<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  label="First Name"
                  label-for="firstname"
                >
                  <b-form-input
                    id="firstname"
                    v-model="userForm.first_name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="First Name"
                    @keypress="isLetter($event)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  label="Last Name"
                  label-for="lastname"
                >
                  <b-form-input
                    id="lastname"
                    v-model="userForm.last_name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Last Name"
                    @keypress="isLetter($event)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Phone"
                rules="required"
              >
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="userForm.phone"
                    :state="getValidationState(validationContext)"
                    placeholder="Phone"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <small
                  v-if="validationErrors.phone"
                  class="text-danger"
                >{{ validationErrors.phone.toString() }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Username"
              >
                <b-form-group
                  label="Username"
                  label-for="username"
                >
                  <b-form-input
                    id="username"
                    v-model="userForm.username"
                    :state="getValidationState(validationContext)"
                    autocomplete="off"
                    placeholder="Username"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="validationErrors.username"
                    class="text-danger"
                  >{{ validationErrors.username.toString() }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="userForm.email"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Email"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="validationErrors.eamil"
                    class="text-danger"
                  >{{ validationErrors.eamil.toString() }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="!$route.params.id"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|password"
              >
                <b-form-group label="Password">
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="userForm.password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      class="form-control-merge"
                      placeholder="Password"
                      autocomplete="off"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              v-if="$route.params.id"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
              >
                <b-form-group label="Password">
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="userForm.password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="Password"
                      autocomplete="off"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Gender"
              >
                <b-form-group
                  label="Gender"
                  label-for="gender"
                >
                  <b-form-radio-group
                    id="gender"
                    v-model="userForm.gender"
                    :options="genderOptions"
                    value-field="value"
                    text-field="name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Role"
              >
                <b-form-group
                  label="Role"
                  label-for="role"
                >
                  <v-select
                    id="type"
                    v-model="userForm.roles"
                    label="name"
                    :reduce="role => role.id"
                    :options="rolesPermitions"
                    multiple
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="!$route.params.id"
              md="6"
            >
              <!-- PIN -->
              <validation-provider
                #default="{ errors }"
                name="Pin"
                rules="required"
              >
                <b-form-group label="Pin">
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="pin"
                      v-model.number="userForm.pin"
                      :type="pinFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="pin"
                      placeholder="Pin"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="pinToggleIcon"
                        @click="togglepinVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="validationErrors.pin"
                  class="text-danger"
                >{{ validationErrors.pin.toString() }}</small>
              </validation-provider>
            </b-col>
            <b-col
              v-if="$route.params.id"
              md="6"
            >
              <!-- PIN -->
              <validation-provider
                #default="{ errors }"
                name="Pin"
              >
                <b-form-group label="Pin">
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="pin"
                      v-model.number="userForm.pin"
                      :type="pinFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="pin"
                      placeholder="Pin"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="pinToggleIcon"
                        @click="togglepinVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="validationErrors.pin"
                  class="text-danger"
                >{{ validationErrors.pin.toString() }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <h5>Activation date</h5>
                <flat-pickr
                  v-model="userForm.activation_at"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Profile Picture
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="preview"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-card-text class="my-50" />
                    <div class="d-inline-block">
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        placeholder="Choose file"
                        @change="onChange"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                @click="addUser()"
              >
                Save
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, email, password, pincode,
} from '@validations'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { useRouter } from '@core/utils/utils'
import previewImage from '@/global-compositions/previewImage'
import user from '../users'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { vSelect, flatPickr },
  mixins: [togglePasswordVisibility, previewImage],
  data() {
    return {
      required,
      email,
      password,
      pincode,
      pinErrors: null,
      loader: false,
      validationErrors: {
        pin: '',
        username: '',
        email: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    pinToggleIcon() {
      return this.pinFieldType === 'number' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'userForm.pin': function (value) {
      if (value) {
        if (value.toString().length < 6) {
          this.pinErrors = ' The pin must be at least 6 numbers.'
        } else {
          this.pinErrors = ''
        }
      }
    },
  },
  setup() {
    const { router } = useRouter()
    const {
      userForm, genderOptions, rolesOptions, roles,
    } = user()
    const {
      getValidationState,
    } = formValidation()
    const { formData, setFormData } = handleFormData()
    const rolesPermitions = ref([])

    return {
      getValidationState,
      userForm,
      genderOptions,
      rolesOptions,
      roles,
      formData,
      setFormData,
      rolesPermitions,
      router,
    }
  },
  mounted() {
    this.showUser()
    this.getroles()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    isLetter(e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true // Match with regex
      e.preventDefault() // If not match, don't add to input text
    },
    showUser() {
      if (this.$route.params.id) {
        axios.get(`users/${this.$route.params.id}`).then(res => {
          if (res.status === 200) {
            this.preview = res.data.data.profile_picture
            this.userForm = res.data.data
          }
        })
      } else {
        return false
      }
      return true
    },
    addUser() {
      if (this.$route.params.id) {
        this.loader = true
        this.setFormData(this.userForm)
        this.formData.append('_method', 'PUT')
        axios.post(`users/${this.$route.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.returnToPageAfterSubmit()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Succesfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(e => {
          if (e) {
            Object.assign(this.validationErrors, e.response.data.errors)
            this.pinErrors = 'The pin format is invalid, avoid using Sequential Letters and Numbers '
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.loader = true
            this.setFormData(this.userForm)
            axios.post('users', this.formData).then(res => {
              if (res.status === 200 || res.status === 201) {
                this.returnToPageAfterSubmit()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Added Succesfully',
                    icon: 'BellIcon',
                    variant: 'success',
                  },
                })
              }
            }).catch(e => {
              Object.assign(this.validationErrors, e.response?.data?.errors)
              this.pinErrors = 'The pin format is invalid, avoid using Sequential Letters and Numbers '
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
    async getroles() {
      await axios.get('get-roles').then(res => {
        if (res.status === 200) {
          this.rolesPermitions = res.data?.data
        }
      })
    },
    returnToPageAfterSubmit() {
      if (this.router.currentRoute.path.includes('users/admin')) {
        this.router.push({ name: 'users' })
      } else if (this.router.currentRoute.path.includes('users/merchant')) {
        this.router.push({ name: 'merchant-users' })
      } else {
        this.router.push({ name: 'customer-users' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
